// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-sale-js": () => import("./../../../src/pages/terms-of-sale.js" /* webpackChunkName: "component---src-pages-terms-of-sale-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

